<template>
  <div class="table">
    <header class="header">
      <div>
        <section>
          <span>状态：</span>
          <el-select v-model="params.state" class="table-header-status">
            <el-option value="" label="全部"></el-option>
            <el-option value="1" label="已停用"></el-option>
            <el-option value="2" label="已启用"></el-option>
            <el-option value="4" label="草稿"></el-option>
          </el-select>
        </section>
        <section>
          <span>文章标题：</span>
          <el-input
            v-model="params.title"
            class="article-name"
            placeholder="请输入文章标题"
          ></el-input>
        </section>
        <el-button size="mini" @click="clear">重置</el-button>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
      </div>
      <el-button type="primary" @click="create">+新建</el-button>
    </header>
    <DragTable
      class="drag-table"
      :listQuery="list"
      :header="header"
      @sort-change="sortChange"
    >
      <template #status="{ row }">
        <span
          class="status"
          :class="{ active: row.state === 1, yellow: row.state === 4 }"
          >{{ state[row.state] }}</span
        >
      </template>
      <template #link="{row}"><span style="cursor: pointer;color:#4F5BFF;" @click="openLink(row)">{{row.url}}</span></template>
      <template #control="{ row }">
        <div class="control">
          <span @click="edit(row)">编辑</span>
          <span :class="{ active: row.state === 2 }" @click="open(row)">{{
            row.state === 2 ? "停用" : "启用"
          }}</span>
        </div>
      </template>
    </DragTable>
    <footer class="table-footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  setup(props, { root }) {
    const params = reactive({
      title: undefined,
      state: '',
      page: 1,
      size: 10,
      total: 10,
      create_time_order: null,
      update_time_order: null,
    });
    const state = {
      1: "已停用",
      2: "已启用",
      4: "草稿",
    };
    const clear = () => {
      params.title = undefined;
      params.create_time_order = null,
      params.update_time_order = null,
      params.state = '';
      params.page = 1;
      params.size = 10;
      getList();
    };
    const header = [
      { name: "文章ID", value: "id", width: 100, fixed: "left" },
      { name: "文章标题", value: "title", width: 200, fixed: "left" },
      { name: "文章标签", value: "label", width: 120 },
      { name: "文章链接", value: "url", width: 200, slot: 'link' },
      { name: "创建时间", value: "create_time", width: 205, sort: 'custom' },
      { name: "更新时间", value: "update_time", width: 205, sort: 'custom' },
      { name: "PV", value: "pv" },
      { name: "UV", value: "uv" },
      { name: "发布人", value: "author", width: 120 },
      { name: "创建人", value: "create_user", width: 120 },
      {
        name: "状态",
        value: "state",
        width: 80,
        slot: "status",
        fixed: "right",
      },
      { name: "操作", value: "", slot: "control", width: 100, fixed: "right" },
    ];
    const list = reactive({
      data: [],
    });
    const getList = () => {
      try {
        root.$axios
          .get(root.$store.state.CMS.url + "/cms/article/list", {
            params,
          })
          .then((res) => {
            list.data = res.data.data;
            params.total = res.data.total;
          });
      } catch (error) {
        console.error(error);
      }
    };
    const sortChange = (val) =>{
      params.update_time_order = null
      params.create_time_order = null
      if(val.prop === 'create_time' && val.order === 'descending') {
        params.create_time_order = 1
      }
      if(val.prop === 'create_time' && val.order === 'ascending') {
        params.create_time_order = 2
      }
      if(val.prop === 'update_time' && val.order === 'ascending') {
        params.update_time_order = 2
      }
      if(val.prop === 'update_time' && val.order === 'descending') {
        params.update_time_order = 1
      }
      getList()
    }
    const edit = (data) => {
      root.$router.push("/CMS/articleDetail?id=" + data.id);
    };
    const create = () => {
      root.$router.push(`/CMS/articleDetail`);
    };
    const open = async (data) => {
      try {
        const confirm = await root.$goDialog(
          `确定要${data.state === 2 ? "停用" : "启用"}吗？`
        );
        if (!confirm) return;
        const res = await root.$axios.post("/cms/article/set_state",
          {
            id: data.id,
            state: data.state !== 2 ? 2 : 1,
          }
        );
        if (res.code === 10000) {
          root.$message.success(`${data.state === 2 ? "停用" : "启用"}成功`);
          getList()
        }
      } catch (error) {
        console.error(error);
      }
    };
    const openLink = row=>{
      window.open(row.url)
    }
    const search = () => {
      params.page = 1
      getList()
    }
    const handleSizeChange = (size) => {
      params.size = size;
      params.page = 1
      getList()
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList()
    };
    getList();
    return {
      list,
      header,
      params,
      state,
      openLink,
      search,
      clear,
      edit,
      create,
      open,
      handleSizeChange,
      handleCurrentChange,
      getList,
      sortChange
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
.table {
  // display: flex;
  // flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
  }
  section {
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 24px;
    height: 30px;
    ::v-deep input {
      height: 30px;
      line-height: 30px;
    }
  }
}
.title-style {
  @include textover();
}
.status {
  color: $safeColor;
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
  &.yellow {
    color: #ffb45b;
    &::after {
      background-color: #ffb45b;
    }
  }
}
.control {
  display: flex;
  justify-content: space-around;
  span {
    cursor: pointer;
    display: block;
    color: $mainColor;
    &.active {
      color: $dangerColor;
    }
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
